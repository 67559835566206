import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Layout
import { SEOContent } from 'layouts';

// Templates
import { MarkdownTemplate } from 'templates';

// Types
interface Props {
  data: {};
}

export default ({ data }: Props) => {
  const content = get(data, 'airtable.data') || '';
  const titleDate = get(content, 'created_date') || 'Single Meeting Minutes';

  return (
    <SEOContent title={`Notes recorded: ${titleDate}`}>
      <MarkdownTemplate content={content} postTitle={titleDate} />
    </SEOContent>
  );
};

export const query = graphql`
  query($meetingMinutesId: Int) {
    airtable(
      table: { eq: "Meeting Minutes" }
      fields: { meetingMinutesId: { eq: $meetingMinutesId } }
    ) {
      data {
        markdown_content
        created_date(formatString: "LLLL")
      }
    }
  }
`;
